<template>
    <div class="detail-more">
        <!-- 封面图片 -->
        <div class="detail-more-cover">
            <el-image
                :src="itemData.skin"
                fit="scale-down"
                lazy
                class="detail-more-cover-pic">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>
        <!-- 文字说明 -->
        <div class="detail-more-explain">
            <slot></slot>
        </div>
        <!-- 预览图片 -->
        <div class="detail-more-cover"
            v-for="item in itemData.images"
            :key="item.id">
            <el-image
                :src="item"
                fit="scale-down"
                lazy
                class="detail-more-cover-pic">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            itemData: {
                type: Object,
                default() {return {}}
            }
        }
    }
// 组件调用：memberDetailInfo
</script>

<style scoped>
/* 容器 */
.detail-more{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

/* 封面图片 */
.detail-more-cover{
    position: relative;
    width: 14%;
    border-radius: 6px;
    overflow: hidden;
}
.detail-more-cover::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.detail-more-cover + .detail-more-cover{
    margin-left: 10px;
}
.detail-more-cover-pic{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* 文字说明 */
.detail-more-explain{
    flex: 1;
}
</style>